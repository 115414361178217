export const columnsCalls = [
	{
		headerName: 'Дата',
		field: 'date',
		width: 350,
		sort: true,
	},
	{
		headerName: 'Телефон',
		field: 'msisdn',
		input: true,
		width: 250,
	},
	{
		headerName: 'Статус звонка',
		field: 'status',
		width: 250,
		select: true,
		sort: false,
		selectValue: 'all',
		menuItem: [
			{ value: 'all', label: 'Все' },
			{ value: 'callFailed', label: 'Ошибка' },
			{ value: 'answered', label: 'Отвечен' },
			{ value: 'cancelled', label: 'Отменен' },
		],
	},
	{
		headerName: 'Файл',
		field: 'media',
		width: 250,
	},
];
