import { combineReducers, configureStore } from '@reduxjs/toolkit';
import profileReducer from './reducers/ProfileSlice';
import infoReducer from './reducers/InfoSlice';
import callsReducer from './reducers/CallsSlice';
import viewReducer from './reducers/ViewSlice';
import statsReducer from './reducers/StatsSlice';
import ordersReducer from './reducers/OrdersSlice';
import pagesReducer from './reducers/PagesSlice';
import modalsReducer from './reducers/ModalSlice';
import videoReducer from './reducers/VideoSlice';
import evaluationsReducer from './reducers/EvaluationsSlice';

const rootReducer = combineReducers({
	profileReducer,
	infoReducer,
	callsReducer,
	videoReducer,
	viewReducer,
	statsReducer,
	ordersReducer,
	pagesReducer,
	modalsReducer,
	evaluationsReducer,
});

export const store = configureStore({
	reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
