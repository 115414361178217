export default function Play(): JSX.Element {
	return (
		<svg
			width="19"
			height="18"
			viewBox="0 0 19 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.98773 15.1347C13.3758 15.1347 16.1224 12.3881 16.1224 8.99994C16.1224 5.61183 13.3758 2.86522 9.98773 2.86522C6.59962 2.86522 3.85302 5.61183 3.85302 8.99994C3.85302 12.3881 6.59962 15.1347 9.98773 15.1347ZM9.98773 16.4979C14.1288 16.4979 17.4857 13.141 17.4857 8.99994C17.4857 4.85892 14.1288 1.50195 9.98773 1.50195C5.84671 1.50195 2.48975 4.85892 2.48975 8.99994C2.48975 13.141 5.84671 16.4979 9.98773 16.4979Z"
				fill="#797A80"
			/>
			<path
				d="M12.7143 8.99994L8.62446 11.9515V6.04837L12.7143 8.99994Z"
				fill="#797A80"
			/>
		</svg>
	);
}
