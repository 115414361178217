import React, { FC } from 'react';
import styles from './BalanceVideoButton.module.css';

type BalanceVideoButtonProps = {
	onClick: () => void;
	arrow: JSX.Element;
	icon: JSX.Element;
	currentDiskSize: number;
	totalDiskSize: number;
};

const BalanceVideoButton: FC<BalanceVideoButtonProps> = ({
	arrow,
	icon,
	currentDiskSize,
	totalDiskSize,
	onClick,
}) => {
	const formatMbytes = (b: number, decimals = 2) => {
		if (b === 0) {
			return '0';
		} else {
			const k = 1024;
			const dm = decimals < 0 ? 0 : decimals;
			const sizes = ['байт', 'Кб', 'Мб', 'Гб', 'Тб'];
			const i = Math.floor(Math.log(b) / Math.log(k));
			return parseFloat((b / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
		}
	};

	return (
		<button className={styles.balance_button} onClick={onClick}>
			<div className={styles.align}>
				<p>{icon}</p>
				{formatMbytes(currentDiskSize)}
				<span>/</span>
				{formatMbytes(totalDiskSize)}
				<p>{arrow}</p>
			</div>
		</button>
	);
};

export default BalanceVideoButton;
