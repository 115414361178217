import React, { FC } from 'react';
import { useAppSelector } from '../../hooks/redux';
import md5 from 'md5';
import styles from './apiForm.module.css';
import { URL_SERVER } from '../../utils/const';

interface ApiFormProps {
	number: string;
	orders: string;
	email: string;
	phone: string;
	voice: string;
}

const ApiForm: FC<ApiFormProps> = ({ number, orders, email, phone, voice }) => {
	const { profile } = useAppSelector((state) => state.profileReducer);
	const passwordValue: string = Object.prototype.hasOwnProperty.call(
		profile,
		'encryptedPassword_shop'
	)
		? profile!.encryptedPassword_shop!
		: profile!.encryptedPassword;
	// const token = btoa(`${profile!.login}:${atob(passwordValue)}`);
	const date = new Date();
	const day = date.getDate();
	const month =
		date.getMonth() < 10 ? '0' + date.getMonth().toString() : date.getMonth();
	const tokenServer = md5(
		`${phone}${profile!.token_access}${day}${month}${date.getFullYear()}`
	).toString();
	const href = `${URL_SERVER}/users/tasks?number=${number}&orders=${orders}&email=${email}&phone=${phone}&voice=${voice}&token=${' '}`;
	return (
		<div>
			{number || orders || email || phone ? (
				<div>
					<div className={styles.separate}>
						<p className={styles.label_for_context_text}>
							URL для обращения к приложению со стороны внешних систем
						</p>
						<a
							rel="noreferrer"
							target="_blank"
							href={href}
							className={styles.link_url}
						>
							{href}
						</a>
						<p>
							где параметры <b>number</b> - номер заказа @number@,
						</p>
						<p>
							<b>orders</b> - состав заказа @text@,
						</p>
						<p>
							<b>email</b> - e-mail покупателя @email@,
						</p>
						<p>
							<b>phone</b> - номер покупателя,
						</p>
						<p>
							<b>voice</b> - голос скрипта,
						</p>
						<p>
							<b>token</b> - токен доступа, btoa(логин:пароль)
						</p>
						<p>
							Подробнее можно прочитать в{' '}
							<a
								target="_blank"
								href="https://app.swaggerhub.com/apis/Telephone-System/shop.kloud.one/0.1"
							>
								документации
							</a>
						</p>
					</div>
					<div className={styles.separate}>
						<p className={styles.label_for_context_text}>
							URL для обращения к внешним системам со стороны приложения
						</p>
						<a href="#">
							{profile!.url}?{orders ? `orders=${number}&` : ''}
							&result=@result@&token={tokenServer}
						</a>
						<p className="example_uri_params">
							где параметры <b>orderid</b> - номер заказа @number@,
						</p>
						<p>
							<b>result</b> - Обработка заказов (1-заказ подтвержден, 2-заказ
							отменен, 3-заказ не подтвержден),
						</p>
						<p>
							<b>token</b> - токен, md5(номер покупателя+токен доступа+дата(в
							формате ddmmyyyy)
						</p>
						<p>
							Подробнее можно прочитать в{' '}
							<a
								target="_blank"
								href="https://app.swaggerhub.com/apis/Telephone-System/shop.kloud.one/0.1"
							>
								документации
							</a>
						</p>
					</div>
				</div>
			) : null}
			<div className="form-group wrapper_instruction">
				<p className={styles.label_for_context_text}>
					Настройка подтверждения заказов
				</p>
				<p>
					1. В поле &quot;URL обработчика статусов заказов&quot; укажите
					контроллер внешней системы, который принимает статусы заказов.
					<br />
					Например, http://test.ru/test.php
				</p>
				<p>
					2. В поле &quot;Токен доступа&quot; укажите токен, который будет
					использоваться при получении статусов заказа со стороны внешних
					систем,
					<br />
					Например, Наименование внешней системы
				</p>
			</div>
		</div>
	);
};

export default ApiForm;
