import React, { FC, useCallback, useState } from 'react';
import styles from './playerVideo.module.css';
import { API_PREFIX } from '../../utils/const';
import { setErrorApp } from '../../store/reducers/InfoSlice';
import { useAppDispatch } from '../../hooks/redux';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

interface IPlayerVideo {
	videoLink: string;
}

const PlayerVideo: FC<IPlayerVideo> = ({ videoLink }) => {
	// const [isSending, setIsSending] = useState(false);
	// const [url, setUrl] = useState();
	// const dispatch = useAppDispatch();
	const [openSet, setOpenSet] = useState<boolean>(false);
	const handleOpenSettings = () => {
		// handleClick();
		setOpenSet(true);
	};
	const handleCloseSetings = () => {
		setOpenSet(false);
	};

	// const handleClick = useCallback(async () => {
	// 	if (isSending) return;
	// 	try {
	// 		setIsSending(true);

	// 		const response = await fetch(`${API_PREFIX}/orders/${'videoLink'}`, {
	// 			method: 'GET',
	// 			credentials: 'include',
	// 		});
	// 		const reader = response.body!.getReader();
	// 		const chunks: any[] = [];
	// 		// eslint-disable-next-line
	// 		while (true) {
	// 			const { done, value } = await reader.read();
	// 			if (done) break;
	// 			chunks.push(value);
	// 		}
	// 		const blob = new Blob(chunks, { type: 'video/mp4' });
	// 		const url = window.URL.createObjectURL(blob);
	// 		// @ts-ignore
	// 		setUrl(url);
	// 	} catch (e) {
	// 		dispatch(
	// 			setErrorApp({
	// 				isErrorApp: true,
	// 				errorApp: 'Не удалось загрузить видео файл',
	// 			})
	// 		);
	// 	} finally {
	// 		setIsSending(false);
	// 	}
	// }, [isSending, videoLink]);

	return (
		<div className={styles.player}>
			<IconButton onClick={handleOpenSettings} aria-label="open_settings">
				<PlayArrowIcon className={styles.playArrowIcon} />
			</IconButton>
			<Dialog scroll="body" open={openSet} onClose={handleCloseSetings}>
				<DialogTitle className={styles.profile_label}>
					Видео упаковки
					<button onClick={handleCloseSetings} className={styles.close_modal}>
						×
					</button>
				</DialogTitle>
				<div className={styles.button}>
					<video controls width="550" height="400">
						<source src={videoLink} type="video/mp4" />
						<source src={videoLink} type="video/webm" />
					</video>
				</div>
			</Dialog>
		</div>
	);
};

export default PlayerVideo;
