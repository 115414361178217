import { createSlice } from '@reduxjs/toolkit';

const newStartDate = `${new Date().getFullYear()}-${
	new Date().getMonth() + 1 < 10
		? `0${new Date().getMonth() + 1}`
		: new Date().getMonth() + 1
}-${
	new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()
} 00:00:00`;

const newEndDate = `${new Date().getFullYear()}-${
	new Date().getMonth() + 1 < 10
		? `0${new Date().getMonth() + 1}`
		: new Date().getMonth() + 1
}-${
	new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()
} 23:59:59`;

const initialState = {
	isLoading: false,
	evaluations: [],
	sort: {
		sortField: 'date',
	},
	order: {
		sortOrder: '-1',
	},
	page: 1,
	skip: 0,
	searchStart: newStartDate,
	searchEnd: newEndDate,
	// limit: 10,
	searchOption: 'date',
	/*search: {
        script: 'shop',
        clientID: 'cf9d40ca-c4e5-4599-aaac-a293c6da04de',
        gdate:`${newStartDate}|${newEndDate}`
    },*/
	search: {},
	totalSize: 0,
	filter: {},
	sizePerPage: 10,
	callType: 'feedBack',
};

export const EvaluationsSlice = createSlice({
	name: 'evaluations',
	initialState,
	reducers: {
		setLoading: (state, data) => {
			state.isLoading = data.payload;
		},
		setEvaluations: (state, data) => {
			state.evaluations = data.payload;
		},
		setTotalSizeEvaluations: (state, data) => {
			state.totalSize = data.payload;
		},
		setPageEvaluations: (state, data) => {
			state.page = data.payload;
		},
		setStart: (state, data) => {
			state.skip = data.payload;
		},
		setSort: (state, sort) => {
			state.sort = sort.payload;
		},
		setOrder: (state, order) => {
			state.order = order.payload;
		},
		setFilter: (state, data) => {
			state.filter = data.payload;
		},
		setSearch: (state, data) => {
			// state.search.push({data.payload.type:data.payload.value});
			Object.assign(state.search, data.payload);
		},
		setSearchOption: (state, data) => {
			state.searchOption = data.payload;
		},
		setSizePerPageEvaluations: (state, sizePerPage) => {
			state.sizePerPage = sizePerPage.payload;
		},
		setSearchStartEvaluations: (state, searchStart) => {
			state.searchStart = searchStart.payload;
		},
		setSearchEndEvaluations: (state, searchEnd) => {
			state.searchEnd = searchEnd.payload;
		},
		setCallType: (state, data) => {
			state.callType = data.payload;
		},
	},
});

export default EvaluationsSlice.reducer;
export const {
	setLoading,
	setEvaluations,
	setTotalSizeEvaluations,
	setPageEvaluations,
	setSizePerPageEvaluations,
	setSort,
	setOrder,
	setStart,
	setSearchStartEvaluations,
	setSearchEndEvaluations,
	setSearch,
	setCallType,
} = EvaluationsSlice.actions;
