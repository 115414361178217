import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiOutlinedInput-input': {
			border: '1px solid #E0E1E6',
			borderRadius: 4,
			boxSizing: 'border-box',
			// padding: '9px 12px',
			height: 40,
			width: '485px',
			'&:hover': {
				border: '1px solid #484A4D',
			},
			'&:active': {
				border: '1.5px solid #333333',
			},
		},
	},
	error: {
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiOutlinedInput-input': {
			border: '1px solid #F04924',
			borderRadius: 4,
			boxSizing: 'border-box',
			padding: '9px 12px',
			height: 40,
			width: '485px',
			'&:focus': {
				border: '1px solid #F04924',
			},
			'&:active': {
				border: '1.5px solid #F04924',
			},
		},
		'& .MuiFormHelperText-contained': {
			fontSize: '13px',
			lineHeight: '18px',
			color: '#C91C00',
			position: 'absolute',
			top: 45,
			width: '485px',
			margin: 0,
		},
	},
	success: {
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiOutlinedInput-input': {
			border: '1px solid #4CD49B',
			borderRadius: 4,
			boxSizing: 'border-box',
			padding: '9px 12px',
			height: 40,
			width: '485px',
			'&:focus': {
				border: '2px solid #4ABF73',
			},
			'&:active': {
				border: '1.5px solid #4CD49B',
			},
		},
	},
	disabled: {
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none',
		},
		'& .MuiOutlinedInput-input': {
			backgroundColor: '#fcfcfc',
			border: '1px solid #E0E2E7',
			borderRadius: 4,
			boxSizing: 'border-box',
			padding: '9px 12px',
			height: 40,
			width: '485px',
		},
	},
}));

type CustomInputProps = {
	placeholder: string;
	mode: 'root' | 'disabled' | 'error' | 'success';
	value: any;
	changeInput: any;
	errorMessage: string;
	focus?: boolean;
	classes?: string;
};

const CustomInput: FC<CustomInputProps> = ({
	placeholder,
	mode,
	value,
	changeInput,
	errorMessage,
	focus,
}) => {
	const classes = useStyles();

	const changeValue = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		changeInput(e.target.value);
	};

	return (
		<TextField
			className={classes[mode]}
			variant="outlined"
			autoComplete="off"
			value={value ? value : ''}
			placeholder={placeholder}
			onChange={changeValue}
			helperText={mode === 'error' ? errorMessage : ''}
			autoFocus={focus}
		/>
	);
};

export default CustomInput;
