import { createSlice } from '@reduxjs/toolkit';

const newStartDate = `${new Date().getFullYear()}-${
	new Date().getMonth() + 1 < 10
		? `0${new Date().getMonth() + 1}`
		: new Date().getMonth() + 1
}-${
	new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()
} 00:00:00`;

const newEndDate = `${new Date().getFullYear()}-${
	new Date().getMonth() + 1 < 10
		? `0${new Date().getMonth() + 1}`
		: new Date().getMonth() + 1
}-${
	new Date().getDate() < 10 ? `0${new Date().getDate()}` : new Date().getDate()
} 23:59:59`;

const initialState = {
	view: 'orders',
	tab: 'calls',
	searchStart: newStartDate,
	searchEnd: newEndDate,
};

export const viewSlice = createSlice({
	name: 'view',
	initialState,
	reducers: {
		setView: (state, data) => {
			state.view = data.payload;
		},
		setTab: (state, data) => {
			state.tab = data.payload;
		},
		setSearchStart: (state, searchStart) => {
			state.searchStart = searchStart.payload;
		},
		setSearchEnd: (state, searchEnd) => {
			state.searchEnd = searchEnd.payload;
		},
	},
});

export const { setView, setTab, setSearchStart, setSearchEnd } =
	viewSlice.actions;

export default viewSlice.reducer;
