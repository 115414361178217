import React, { FC } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
	arrow: {
		color: '#000',
		width: 60,
	},
	tooltip: {
		backgroundColor: '#000',
		color: '#FFFFFF',
		fontFamily: 'Inter',
		fontStyle: 'normal',
		fontWeight: 'normal',
		fontSize: '13px',
		lineHeight: '18px',
		padding: '8.5px 12px 9.5px',
		boxShadow:
			'0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 32px rgba(0, 0, 0, 0.16);',
		borderRadius: 2,
		marginLeft: 23,
	},
}));

type IconTooltipProps = {
	TransitionComponent: any;
	TransitionProps: { timeout: number };
	title: string;
	placement:
		| 'bottom'
		| 'left'
		| 'right'
		| 'top'
		| 'bottom-end'
		| 'bottom-start'
		| 'left-end'
		| 'left-start'
		| 'right-end'
		| 'right-start'
		| 'top-end'
		| 'top-start';
	arrow: boolean;
	children: JSX.Element;
};

const IconTooltip: FC<IconTooltipProps> = ({
	title,
	placement,
	arrow,
	TransitionComponent,
	TransitionProps,
	children,
}) => {
	const classes = useStyles();
	return (
		<Tooltip
			arrow={arrow}
			classes={classes}
			placement={placement}
			title={title}
			TransitionComponent={TransitionComponent}
			TransitionProps={TransitionProps}
			children={children}
		/>
	);
};

export default IconTooltip;
