import React, { FC } from 'react';
import { Modal, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { SxProps } from '@mui/system';
import styles from './modalCustom.module.css';

interface ModalCustomProps {
	open: boolean;
	handleClose: () => void;
	text: string;
}

const style: SxProps = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
	textAlign: 'center',
};

const ModalCustom: FC<ModalCustomProps> = ({ open, handleClose, text }) => {
	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography
						id="modal-modal-description"
						className={styles.typography}
					>
						{text}
					</Typography>
					<button className={styles.button} onClick={() => handleClose()}>
						OK
					</button>
				</Box>
			</Modal>
		</div>
	);
};

export default ModalCustom;
