import React, { FC, useEffect, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	FormControl,
	FormLabel,
	InputLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	SelectChangeEvent,
	Tooltip,
} from '@mui/material';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import styles from './SettingsVoiceMailForm.module.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import { updateProfileVoiceMails } from '../../service/UsersService';
import { setErrorApp, setInfoApp } from '../../store/reducers/InfoSlice';
import PlayTextButton from '../PlayTextButton/PlayTextButton';
import {
	CALL_TIME,
	DEF_VOICE,
	REJECT_TIME,
	RETRY_CALLS,
	STYLE_TIME,
	TEXT_VOICE_MAILS,
	VOICE_MAILS_ACTIVATE,
} from '../../utils/const';
import { fetchProfile } from '../../service/ProfileServiceThunk';
import ReactDOM from 'react-dom';
import moment from 'moment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ruLocale from 'date-fns/locale/ru';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { DatePicker } from '@mui/lab';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DialogContent from '@mui/material/DialogContent';

type SettingsVoiceMailFormProps = {
	open: boolean;
	setClose: (value: boolean) => void;
};
const SettingsVoiceMailForm: FC<SettingsVoiceMailFormProps> = ({ open, setClose }) => {
	const { profile } = useAppSelector((state) => state.profileReducer);
	const voiceValue: string =
		typeof profile?.voice_voicemail === 'string'
			? profile?.voice_voicemail
			: DEF_VOICE;

	const initTextVoiceMails = () => {
		if (profile?.tasks_text_voicemail) return profile?.tasks_text_voicemail;
		return TEXT_VOICE_MAILS;
	};
	const initVoiceMails = () => {
		if (profile?.enable_voicemail === true)
			return profile?.enable_voicemail;
		return VOICE_MAILS_ACTIVATE;
	};

	const initCallTime: string =
		typeof profile?.callTime_voicemail === 'string' ? `${profile?.callTime_voicemail}` : CALL_TIME;
	const initRejectTime: string =
		typeof profile?.rejectTime_voicemail === 'string'
			? `${profile?.rejectTime_voicemail}`
			: REJECT_TIME;
	const initRetryCalls: string =
		typeof profile?.retry_call_shop_voicemail === 'string'
			? `${profile?.retry_call_shop_voicemail}`
			: RETRY_CALLS;
	const initStyleTime: string =
		typeof profile?.styleTime_voicemail === 'string'
			? `${profile?.styleTime_voicemail}`
			: STYLE_TIME;

	const initStartDate = () => {
		if (profile?.startDate_voicemail) {
			const now = new Date();
			const fromBase = new Date(profile?.startDate_voicemail);
			fromBase.setHours(now.getHours());
			fromBase.setMinutes(now.getMinutes());
			fromBase.setSeconds(now.getSeconds());
			return fromBase;
		}
		return new Date();
	};
	const initWeekdays = () => {
		if (profile?.weekdays_voicemail) return profile?.weekdays_voicemail;
		return [];
	};

	const [nameVoiceMails, setNameVoiceMails] = useState<string>(profile?.name_voicemail || '');
	const [textVoiceMails, setTextVoiceMails] = useState<string>(initTextVoiceMails);
	const [enableVoiceMails, setEnableVoiceMails] =
		useState<boolean>(initVoiceMails);
	const [url, setUrl] = useState<string>(profile?.url_voicemail || '');
	const [token, setToken] = useState<string>(profile?.token_access_voicemail || '');
	const [retryCall, setRetryCall] = useState<string>(initRetryCalls);
	const [voice, setVoice] = useState<string>(voiceValue);
	const [disabledPlayButton, setPlayButton] = useState<boolean>(true);
	const [callTime, setCallTime] = useState<string>(initCallTime);
	const [rejectTime, setRejectTime] = useState<string>(initRejectTime);
	const [styleTime, setStyleTime] = useState<string>(initStyleTime);
	const [startDate, setStartDate] = useState<string | Date | null>(
		initStartDate
	);
	const [weekdays, setWeekdays] = useState<string[]>(initWeekdays);
	const [expanded, setExpanded] = React.useState<string | false>(false);
	const dispatch = useAppDispatch();

	const handleChangeAccordion =
		(panel: string) => (evt: React.SyntheticEvent, isExpanded: boolean) => {
			setExpanded(isExpanded ? panel : false);
		};

	useEffect(() => {
		setNameVoiceMails(profile?.name_voicemail ?? '');
		setUrl(profile?.url_voicemail ?? '');
		setToken(profile?.token_access_voicemail ?? '');
		setRetryCall(initRetryCalls);
		setTextVoiceMails(initTextVoiceMails);
		setCallTime(initCallTime);
		setRejectTime(initRejectTime);
		setStyleTime(initStyleTime);
		setStartDate(initStartDate);
		setWeekdays(initWeekdays);
		setVoice(voiceValue);
		setEnableVoiceMails(initVoiceMails)
		// setOpenSet(true);
	}, [profile, open]);

	useEffect(() => {
		textVoiceMails.length
			? setPlayButton(false)
			: setPlayButton(true);
	}, [textVoiceMails]);

	const handleChange = (
		evt: React.ChangeEvent<HTMLInputElement>,
		type: string
	) => {
		if (type === 'name_voicemail') {
			setNameVoiceMails(evt.target.value);
		} else if (type === 'url_voicemail') {
			setUrl(evt.target.value);
		} else if (type === 'token_access_voicemail') {
			setToken(evt.target.value);
		} else if (type === 'tasks_text_voicemail') {
			setTextVoiceMails(evt.target.value);
		} else if (type === 'retry_call_shop_voicemail') {
			const value = evt.target.value;
			const zeroToFourCount = (value.match(/[0-9]/g) || []).length;
			if (
				(zeroToFourCount <= 1 &&
					/^\d$/.test(value) &&
					Number(value) >= 0 &&
					Number(value) <= 9) ||
				value === ''
			) {
				setRetryCall(value);
			}
		} else if (type === 'callTime_voicemail') {
			setCallTime(evt.target.value);
		} else if (type === 'rejectTime_voicemail') {
			setRejectTime(evt.target.value);
		} else if (type === 'styleTime_voicemail') {
			setStyleTime(evt.target.value);
		}
	};

	const changeHandler = (evt: any, type: string) => {
		if (type === 'enable_voicemail') {
			setEnableVoiceMails(evt.target.checked);
		}
	};

	const handleUpdateProfile = async () => {
		if (
			nameVoiceMails.length === 0 ||
			!nameVoiceMails.trim() ||
			url.length === 0 ||
			!url.trim() ||
			token.length === 0 ||
			!token.trim() ||
			retryCall.length === 0 ||
			textVoiceMails.length === 0 ||
			!textVoiceMails.trim()
		) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Необходимо заполнить обязательные поля',
				})
			);
		}
		if (!re.test(callTime) || !re.test(rejectTime)) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Не правильный формат времени(должен быть вида HH:MM)',
				})
			);
		}
		if (styleTime === '0' && !checkValidDate(String(startDate))) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Некорректный формат даты',
				})
			);
		}
		if (styleTime === '0' && checkTimeStart()) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Введите актульную дату',
				})
			);
		}
		// if (styleTime === '0' && !checkTimeAndDate()) {
		// 	return dispatch(
		// 		setErrorApp({
		// 			isErrorApp: true,
		// 			errorApp:
		// 				'Время начала не может быть раньше текущего времени',
		// 		})
		// 	);
		// }
		if (compareTime()) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp:
						'Время начала не может быть позже времени окончания звонка',
				})
			);
		}
		if (Number(retryCall) < 0 || Number(retryCall) > 9) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Количество повторных звонков от 0 до 9',
				})
			);
		}
		if (returnText(textVoiceMails).length > 500) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Длина текста не должна превышать 500 символов',
				})
			);
		}
		if (nameVoiceMails.length > 99) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Наименование магазина не должно превышать 99 символов.',
				})
			);
		}
		if (styleTime === '0' && startDate === null) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Поле дата должно быть заполнено',
				})
			);
		}
		if (styleTime === '2' && weekdays.length === 0) {
			return dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Необходимо выбрать день недели',
				})
			);
		}
		const response = await updateProfileVoiceMails(
			enableVoiceMails,
			nameVoiceMails,
			url,
			token,
			textVoiceMails,
			voice,
			retryCall,
			callTime,
			rejectTime,
			styleTime,
			startDate,
			weekdays,
		);
		if (response.error) {
			dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Произошла ошибка при обновление профиля',
				})
			);
		} else {
			dispatch(
				setInfoApp({ isInfoApp: true, infoApp: 'Данные успешно сохранены!' })
			);
			dispatch(fetchProfile());
			setClose(true);
		}
	};

	const handleSelect = (evt: SelectChangeEvent<any>) => {
		setVoice(evt.target.value);
	};

	const re = /^(?:[01]\d|2[0123]):(?:[012345]\d)$/;

	const checkValidDate = (date: Date | string | number): boolean => {
		return new Date(date).toString() !== 'Invalid Date';
	};

	const checkTimeStart = (): boolean => {
		const now = new Date(String(startDate)).getDate();
		return now < new Date().getDate();
	};

	const returnText = (text: string) => {
		return text.replace('<@name_voicemail@>', '');
	};

	const compareTime = () => {
		const callTimeArr = callTime.split(':');
		const rejectTimeArr = rejectTime.split(':');
		if (Number(callTimeArr[0]) < Number(rejectTimeArr[0])) {
			return false;
		} else if (
			Number(callTimeArr[0]) === Number(rejectTimeArr[0]) &&
			Number(callTimeArr[1]) < Number(rejectTimeArr[1])
		) {
			return false;
		} else {
			// console.log('Задачу не запускаем')
			return true;
		}
	};

	const handleChangeDay = (evt: React.ChangeEvent<HTMLInputElement>) => {
		evt.target.checked
			? setWeekdays([...weekdays, evt.target.name])
			: setWeekdays(
				weekdays.filter((day) => {
					return day !== evt.target.name;
				})
			);
	};

	return ReactDOM.createPortal(
		<Dialog scroll="body" open={open} onClose={() => setClose(true)}>
			<DialogTitle className={styles.profile_label}>
				Голосовые рассылки
				<button onClick={() => setClose(true)} className={styles.close_modal}>
					×
				</button>
			</DialogTitle>
			<div className={styles.container}>
				<div className={styles.wrapper_for_block}>
					<DialogContent className={styles.dialog_content}>
						<FormGroup>
							<FormControlLabel
								control={<Checkbox />}
								onChange={(evt: React.SyntheticEvent<any>) =>
									changeHandler(evt, 'enable_voicemail')
								}
								checked={enableVoiceMails}
								label="Активировать сценарий"
								labelPlacement="end"
							/>
						</FormGroup>
					</DialogContent>
				</div>
				<div className={styles.wrapper_for_block}>
					<Accordion
						sx={{ boxShadow: '0px 1px 1px 0px rgba(0,0,0,0.1)' }}
						expanded={expanded === 'panel1'}
						onChange={handleChangeAccordion('panel1')}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1bh-content"
							id="panel1bh-header"
						>
							<Typography>Подключение магазина</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<TextField
								margin="dense"
								id="name_voicemail"
								label="Наименование магазина"
								fullWidth
								variant="standard"
								defaultValue={profile?.name_voicemail}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'name_voicemail')
								}
								error={nameVoiceMails.length <= 0 || nameVoiceMails.length > 99 || !nameVoiceMails.trim()}
							/>
							<TextField
								margin="dense"
								id="url_voicemail"
								label="URL обработчика голосовых рассылок"
								fullWidth
								variant="standard"
								defaultValue={profile?.url_voicemail}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'url_voicemail')
								}
								error={url.length <= 0 || !url.trim()}
							/>
							<TextField
								margin="dense"
								id="token_access_voicemail"
								label="Токен доступа"
								fullWidth
								variant="standard"
								defaultValue={profile?.token_access_voicemail}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'token_access_voicemail')
								}
								error={token.length <= 0 || !token.trim()}
							/>
							<div className="form-group wrapper_instruction">
								<p className={styles.label_for_context_text}>
									Настройка голосовых рассылок
								</p>
								<p>
									1. В поле &quot;URL обработчика голосовых рассылок&quot; укажите
									контроллер внешней системы, который принимает изменения статуса.
									<br />
									Например, http://test.ru/test.php
								</p>
								<p>
									2. В поле &quot;Токен доступа&quot; укажите токен, который будет
									использоваться при получении статуса со стороны внешних
									систем,
									<br />
									Например, Наименование внешней системы
								</p>
							</div>
						</AccordionDetails>
					</Accordion>
				</div>
				<div className={styles.wrapper_for_block}>
					<Accordion
						sx={{ boxShadow: '0px 1px 1px 0px rgba(0,0,0,0.1)' }}
						expanded={expanded === 'panel2'}
						onChange={handleChangeAccordion('panel2')}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1bh-content"
							id="panel1bh-header"
						>
							<Typography>Настройки сценария</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<TextField
								margin="dense"
								id="tasks_text_voicemail"
								label="Текст уведомления/рассылки"
								fullWidth
								variant="standard"
								multiline
								maxRows={4}
								value={textVoiceMails}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'tasks_text_voicemail')
								}
								error={textVoiceMails.length <= 0 || textVoiceMails.length > 500 ||!textVoiceMails.trim()}
							/>
							<InputLabel
								className={styles.voice_input}
								id="demo-simple-select-label"
							>
								Выбор голоса
							</InputLabel>
							<Select
								className={`${styles.voice_select} change-input-outlined`}
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								defaultValue={voice}
								onChange={handleSelect}
							>
								<MenuItem value={'jane'}>jane</MenuItem>
								<MenuItem value={'oksana'}>oksana</MenuItem>
								<MenuItem value={'omazh'}>omazh</MenuItem>
								<MenuItem value={'zahar'}>zahar</MenuItem>
								<MenuItem value={'ermil'}>ermil</MenuItem>
								<MenuItem value={'marina'}>marina</MenuItem>
								<MenuItem value={'lera'}>lera</MenuItem>
								<MenuItem value={'alexander'}>alexander</MenuItem>
								<MenuItem value={'anton'}>anton</MenuItem>
							</Select>
							<PlayTextButton
								voice={voice}
								text={textVoiceMails}
								disabled={disabledPlayButton}
							/>
						</AccordionDetails>
					</Accordion>
				</div>
				<div className={styles.wrapper_for_block}>
					<Accordion
						sx={{ boxShadow: '0px 1px 1px 0px rgba(0,0,0,0.1)' }}
						expanded={expanded === 'panel3'}
						onChange={handleChangeAccordion('panel3')}
					>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls="panel1bh-content"
							id="panel1bh-header"
						>
							<Typography>Дополнительные настройки</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<TextField
								inputProps={{ inputMode: 'numeric', min: 0, max: 9 }}
								margin="dense"
								id="retry_call_shop_voicemail"
								label="Повторных звонков"
								fullWidth
								variant="standard"
								value={retryCall}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'retry_call_shop_voicemail')
								}
								error={retryCall.length <= 0 || !retryCall.trim()}
							/>
							<TextField
								margin="dense"
								id="callTime_voicemail"
								label="Время начала"
								fullWidth
								variant="standard"
								value={callTime}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'callTime_voicemail')
								}
								error={callTime.length <= 0 || !callTime.trim()}
							/>
							<TextField
								margin="dense"
								id="rejectTime_voicemail"
								label="Прекратить рассылку"
								fullWidth
								variant="standard"
								value={rejectTime}
								onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
									handleChange(evt, 'rejectTime_voicemail')
								}
								error={rejectTime.length <= 0 || !rejectTime.trim()}
							/>
							<FormControl component="fieldset">
								<FormLabel component="legend">Выбор дня</FormLabel>
								<RadioGroup
									row
									aria-label="type_input"
									name="row-radio-buttons-group"
									value={styleTime}
									onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
										handleChange(evt, 'styleTime_voicemail')
									}
								>
									<FormControlLabel
										value="0"
										control={<Radio />}
										label="День"
									/>
									<FormControlLabel
										value="1"
										control={<Radio />}
										label="Каждый день"
									/>
									<FormControlLabel
										value="2"
										control={<Radio />}
										label="По дням недели"
									/>
								</RadioGroup>
							</FormControl>
							<div
								className={`${styles.wrapper_for_datarange} change-input-outlined-datapicker`}
							>
								{styleTime === '0' ? (
									<LocalizationProvider
										locale={ruLocale}
										dateAdapter={AdapterDateFns}
									>
										<DatePicker
											mask={'__.__.____'}
											label="Выберите дату"
											value={startDate}
											onChange={(newValue: any) => {
												setStartDate(newValue);
											}}
											renderInput={(params: any) => (
												<TextField
													{...params}
													helperText={null}
													error={
														new Date(String(startDate)).getDate() <
														new Date().getDate()
													}
												/>
											)}
										/>
									</LocalizationProvider>
								) : null}
								{styleTime === '2' ? (
									<FormControl component="fieldset" variant="standard">
										<FormLabel component="legend">
											Выберите дни недели
										</FormLabel>
										<FormGroup>
											<FormControlLabel
												control={
													<Checkbox
														onChange={handleChangeDay}
														checked={weekdays.includes('1')}
														name="1"
													/>
												}
												label="Понедельник"
											/>
											<FormControlLabel
												control={
													<Checkbox
														onChange={handleChangeDay}
														checked={weekdays.includes('2')}
														name="2"
													/>
												}
												label="Вторник"
											/>
											<FormControlLabel
												control={
													<Checkbox
														onChange={handleChangeDay}
														checked={weekdays.includes('3')}
														name="3"
													/>
												}
												label="Среда"
											/>
											<FormControlLabel
												control={
													<Checkbox
														onChange={handleChangeDay}
														checked={weekdays.includes('4')}
														name="4"
													/>
												}
												label="Четверг"
											/>
											<FormControlLabel
												control={
													<Checkbox
														onChange={handleChangeDay}
														checked={weekdays.includes('5')}
														name="5"
													/>
												}
												label="Пятница"
											/>
											<FormControlLabel
												control={
													<Checkbox
														onChange={handleChangeDay}
														checked={weekdays.includes('6')}
														name="6"
													/>
												}
												label="Суббота"
											/>
											<FormControlLabel
												control={
													<Checkbox
														onChange={handleChangeDay}
														checked={weekdays.includes('0')}
														name="0"
													/>
												}
												label="Воскресенье"
											/>
										</FormGroup>
									</FormControl>
								) : null}
							</div>
						</AccordionDetails>
					</Accordion>
				</div>
				<DialogActions className={styles.dialogActions}>
					<Tooltip
						placement="top"
						title={
							(enableVoiceMails === initVoiceMails() &&
								nameVoiceMails === profile?.name_voicemail &&
								url === profile?.voice_voicemail &&
								token === profile?.token_access_voicemail &&
								callTime === initCallTime &&
								retryCall === initRetryCalls &&
								rejectTime === initRejectTime &&
								styleTime === initStyleTime &&
								voice === voiceValue &&
								(weekdays === initWeekdays() ||
									profile?.weekdays === null) &&
								textVoiceMails === initTextVoiceMails() &&
								moment(new Date(startDate ?? 0)).format('MM-DD-YYYY') ===
								moment(initStartDate()).format('MM-DD-YYYY'))
								? 'Внесите изменения, чтобы сохранить'
								: 'Сохранить'
						}
					>
						<span>
							<Button
								disabled={
									(enableVoiceMails === initVoiceMails() &&
										nameVoiceMails === profile?.name_voicemail &&
										url === profile?.url_voicemail &&
										token === profile?.token_access_voicemail &&
										callTime === initCallTime &&
										retryCall === initRetryCalls &&
										rejectTime === initRejectTime &&
										styleTime === initStyleTime &&
										voice === voiceValue &&
										(weekdays === initWeekdays() ||
											profile?.weekdays === null) &&
										textVoiceMails === initTextVoiceMails() &&
										moment(new Date(startDate ?? 0)).format('MM-DD-YYYY') ===
										moment(initStartDate()).format('MM-DD-YYYY'))
								}
								onClick={handleUpdateProfile}
							>
								Сохранить
							</Button>
						</span>
					</Tooltip>
				</DialogActions>
			</div>
		</Dialog>,
		document.getElementById('voiceMails')!
	);
};

export default SettingsVoiceMailForm;
