import React, { FC, useState } from 'react';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import { InputLabel, SelectChangeEvent } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import styles from './testForm.module.css';

interface TestFormProps {
	updateTestData: (type: string, value: string) => void;
}
const TestForm: FC<TestFormProps> = ({ updateTestData }) => {
	const [voice, setVoice] = useState<string>('oksana');
	const handleChange = (
		evt: React.ChangeEvent<HTMLInputElement>,
		type: string
	) => {
		if (type === 'number') {
			// setNumber(evt.target.value);
			updateTestData('number', evt.target.value.trim());
		} else if (type === 'orders') {
			// setOrders(evt.target.value);
			updateTestData('orders', evt.target.value.trim());
		} else if (type === 'email') {
			// setTestEmail(evt.target.value);
			updateTestData('testEmail', evt.target.value.trim());
		} else if (type === 'phone') {
			// setPhone(evt.target.value);
			updateTestData('phone', evt.target.value.trim());
		}
	};
	const handleSelect = (evt: SelectChangeEvent<any>) => {
		// console.log('событие')
		setVoice(evt.target.value);
		updateTestData('voice', evt.target.value);
	};

	return (
		<DialogContent className={styles.dialog_content}>
			<TextField
				id="number_test"
				fullWidth
				variant="standard"
				label="Номер заказа"
				onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
					handleChange(evt, 'number')
				}
			/>
			<TextField
				id="orders_test"
				fullWidth
				variant="standard"
				label="Состав заказа"
				onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
					handleChange(evt, 'orders')
				}
			/>
			<TextField
				id="email_test"
				fullWidth
				variant="standard"
				label="E-mail покупателя"
				onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
					handleChange(evt, 'email')
				}
			/>
			<TextField
				id="phone_test"
				fullWidth
				variant="standard"
				label="Номер телефона покупателя"
				onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
					handleChange(evt, 'phone')
				}
			/>
			<InputLabel className={styles.voice_input} id="demo-simple-select-label">
				Выбор голоса
			</InputLabel>
			<Select
				className={`${styles.voice_select} change-input-outlined`}
				labelId="demo-simple-select-label"
				id="demo-simple-select"
				defaultValue={voice}
				onChange={handleSelect}
			>
				<MenuItem value={'jane'}>jane</MenuItem>
				<MenuItem value={'oksana'}>oksana</MenuItem>
				<MenuItem value={'omazh'}>omazh</MenuItem>
				<MenuItem value={'zahar'}>zahar</MenuItem>
				<MenuItem value={'ermil'}>ermil</MenuItem>
				<MenuItem value={'marina'}>marina</MenuItem>
				<MenuItem value={'lera'}>lera</MenuItem>
				<MenuItem value={'alexander'}>alexander</MenuItem>
				<MenuItem value={'anton'}>anton</MenuItem>
			</Select>
		</DialogContent>
	);
};

export default TestForm;
