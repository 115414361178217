import resolve from './resolve';
import axios from 'axios';
import { API_PREFIX } from '../utils/const';

export async function loginUser(): Promise<any> {
	return await resolve(
		axios.get(`${API_PREFIX}/auth/login`, {
			withCredentials: true,
		})
	);
}

export async function logoutUser(): Promise<any> {
	return await resolve(
		axios.get(`${API_PREFIX}/auth/logout`, {
			withCredentials: true,
		})
	);
}
