import React, { FC } from 'react';
import styles from './preloader.module.css';

const Preloader: FC = () => {
	return (
		<div className={styles.preloader}>
			<svg className={`${styles.intro} ${styles.go}`} viewBox="0 0 100 88">
				<text
					textAnchor="start"
					x="10"
					y="50"
					className={`${styles.text} ${styles.text_stroke}`}
					clipPath="url(#text1)"
				>
					KLOUD.ONE
				</text>
				<defs>
					<clipPath id="text1">
						<text textAnchor="start" x="10" y="50" className={`${styles.text}`}>
							KLOUD.ONE
						</text>
					</clipPath>
				</defs>
			</svg>
		</div>
	);
};

export default Preloader;
