import React, { FC } from 'react';
import Modal from '@mui/material/Modal';
import ReactDOM from 'react-dom';
import styles from './BitrixModal.module.css';

type BitrixModalProps = {
	open: boolean;
	setClose: (value: boolean) => void;
};

const BitrixModal: FC<BitrixModalProps> = ({ open, setClose }) => {
	const closeModal = () => {
		setClose(false);
	};

	return ReactDOM.createPortal(
		<Modal open={open} onClose={closeModal}>
			<div id="bitrix" className={styles.new_script}></div>
		</Modal>,
		document.getElementById('modal')!
	);
};

export default BitrixModal;
