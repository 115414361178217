import React, { FC, useCallback, useState } from 'react';
import styles from './player.module.css';
import { Button } from '@mui/material';
import { API_PREFIX } from '../../utils/const';
import { setErrorApp } from '../../store/reducers/InfoSlice';
import { useAppDispatch } from '../../hooks/redux';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

interface IPlayer {
	audioLink: string;
}

const Player: FC<IPlayer> = ({ audioLink }) => {
	// const [isPlaying, setIsPlaying] = useState<boolean>(false);
	// const toggleIsPlaying = () => {
	// 	setIsPlaying(!isPlaying);
	// };
	const [isSending, setIsSending] = useState(false);
	const [vocals, setVocals] = useState();
	const dispatch = useAppDispatch();

	const handleClick = useCallback(async () => {
		if (isSending) return;
		try {
			setIsSending(true);

			const response = await fetch(audioLink, {
				method: 'GET',
				credentials: 'include',
			});
			const reader = response.body!.getReader();
			const chunks: any[] = [];
			// eslint-disable-next-line
			while (true) {
				const { done, value } = await reader.read();
				if (done) break;
				chunks.push(value);
			}
			const blob = new Blob(chunks, { type: 'audio/wav' });
			const url = window.URL.createObjectURL(blob);
			// @ts-ignore
			setVocals(url);
		} catch (e) {
			dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Не удалось загрузить аудио файл',
				})
			);
		} finally {
			setIsSending(false);
		}
	}, [isSending, audioLink]);

	const prompt = isSending ? 'Подготовка аудио' : <PlayArrowIcon />;

	const playButton =
		vocals !== undefined ? (
			<audio controls autoPlay>
				<source src={vocals} type="audio/x-wav" />
				<source src={vocals} type="audio/wav" />
				<source src={vocals} type="audio/mp3" />
			</audio>
		) : null;

	return (
		<div className={styles.player}>
			{playButton ? null : (
				<Button className={styles.button} onClick={handleClick}>
					{vocals === undefined ? prompt : null}
				</Button>
			)}
			{playButton}
		</div>
	);
};

export default Player;
