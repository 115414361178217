import React, { FC } from 'react';
import { Menu, MenuItem } from '@mui/material';

export enum ElemInPopover {
	orderStatusRequiresConfirmation = 'Требует подтверждения',
	orderStatusPlanned = 'Запланирован',
	packageStatusRequiresPackaging = 'Требует упаковки',
	packageStatusPlanned = 'Запланирован',
	evaluationStatusRequiresEvaluation = 'Требует оценки',
	evaluationStatusPlanned = 'Запланирован',
}

interface IPopoverComponent {
	open: boolean;
	anchorEl: HTMLButtonElement | null;
	elemInPopover: (ElemInPopover | null)[];
	onClose: () => void;
	onClick: (
		value: 'order_status' | 'package_status' | 'evaluation_status'
	) => void;
}

export const PopoverComponent: FC<IPopoverComponent> = ({
	open,
	anchorEl,
	onClose,
	onClick,
	elemInPopover,
}) => {
	return (
		<Menu
			id="basic-menu"
			anchorEl={anchorEl}
			open={open}
			onClose={onClose}
			MenuListProps={{
				'aria-labelledby': 'basic-button',
			}}
		>
			<>
				{elemInPopover[0] === ElemInPopover.orderStatusRequiresConfirmation ? (
					<MenuItem
						onClick={() => onClick('order_status')}
					>{`${ElemInPopover.orderStatusRequiresConfirmation}`}</MenuItem>
				) : null}
				{elemInPopover[0] === ElemInPopover.orderStatusPlanned ? (
					<MenuItem
						onClick={() => onClick('order_status')}
					>{`${ElemInPopover.orderStatusPlanned}`}</MenuItem>
				) : null}
				{elemInPopover[1] === ElemInPopover.packageStatusRequiresPackaging ? (
					<MenuItem
						onClick={() => onClick('package_status')}
					>{`${ElemInPopover.packageStatusRequiresPackaging}`}</MenuItem>
				) : null}
				{elemInPopover[1] === ElemInPopover.packageStatusPlanned ? (
					<MenuItem
						onClick={() => onClick('package_status')}
					>{`${ElemInPopover.packageStatusPlanned}`}</MenuItem>
				) : null}
				{elemInPopover[2] ===
				ElemInPopover.evaluationStatusRequiresEvaluation ? (
					<MenuItem
						onClick={() => onClick('evaluation_status')}
					>{`${ElemInPopover.evaluationStatusRequiresEvaluation}`}</MenuItem>
				) : null}
				{elemInPopover[2] === ElemInPopover.evaluationStatusPlanned ? (
					<MenuItem
						onClick={() => onClick('evaluation_status')}
					>{`${ElemInPopover.evaluationStatusPlanned}`}</MenuItem>
				) : null}
			</>
		</Menu>
	);
};
