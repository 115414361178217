import React, { FC } from 'react';
import './style.css';

type NavMenuButtonProps = {
	onClick: (value: string) => void;
	text: string;
	arrow?: JSX.Element;
	icon: JSX.Element;
	page: string;
};

const NavMenuButton: FC<NavMenuButtonProps> = ({
	text,
	arrow,
	icon,
	page,
	onClick,
}) => {
	return (
		<button
			className={
				page === text
					? 'nav_menu_button nav_menu_button__active'
					: 'nav_menu_button'
			}
			onClick={() => onClick(text)}
		>
			<div className="align">
				{icon}
				<span>{text}</span>
				{arrow}
			</div>
		</button>
	);
};

export default NavMenuButton;
