export const URL_SERVER = window._env_
	? window._env_.URL_SERVER
	: 'http://localhost:3000';
export const URL_AUTH = window._env_
	? window._env_.URL_AUTH
	: 'https://id.kloud.one/authorize/?redirect_uri=http://localhost:3000/api/v1/auth/callback&client_id=0df5cb99771b406ba9fe91f3b3ee8041';
export const TYPE_SERVICE = window._env_ ? window._env_.STATE_SERVICE : 'shop';
export const LINK_BUY = window._env_
	? window._env_.LINK_BUY
	: 'https://kloud.one/crmtools/#buy';
export const API_PREFIX = window._env_ ? window._env_.API_PREFIX : 'api/v1';
export const TASKS_TEXT =
	'Здравствуйте, это интернет-магазин <@name@>! Хотим подтвердить ваш заказ <@number@>. Счет на оплату уже отправлен на ваш e-mail <@email@>. Для отмены заказа скажите нет.';
export const TASKS_TEXT_FEEDBACK =
	'Здравствуйте Вас приветствует интернет-магазин <@name@>!  Хотим оценить работу интернет магазина по заказу №<@number@>';
export const TEXT_VOICE_MAILS =
	'Здравствуйте! Вас приветствует <@name_voicemail@>! Регистрация в приложении успешно завершена. Теперь вы можете оформить заявки на доставку товаров в вашу торговую точку';
export const TEXT_VOICE_DELIVIRIES =
	'Здравствуйте! Вас приветствует интернет-магазин <@name@>. Вы приобрели <@text@>. Доставка заказа будет <@deliveryType@> по адресу <@address@>. Если адрес доставки не верный, скажите “нет”.';
export const POSITIVE_FEEDBACK = 'Что вам понравилось, оставьте отзыв';
export const NEGATIVE_FEEDBACK = 'Что вам не понравилось, оставьте отзыв';
export const ORDER_CONFIRMED = 'Спасибо, Ваш заказ подтвержден!';
export const ORDER_CANCELLED = 'Спасибо, Ваш заказ отменен!';
export const DELIVERY_CONFIRMED =
	'Трек-номер для отслеживания будет отправлен на Вашу электронную почту.';
export const DELIVERY_CANCELLED =
	'Спасибо, с Вами свяжется менеджер для уточнения деталей доставки.';
export const CALL_TIME = '10:00';
export const REJECT_TIME = '22:00';
export const STYLE_TIME = '1';
export const RETRY_CALLS = '0';
export const RECORDING = true;
export const VOICE_MAILS_ACTIVATE = false;
export const DELIVIRIES_ACTIVATE = false;
export const DEF_VOICE = 'oksana';
export const DEF_FEEDBACK_ACTIVATE = false;
export const URL_SERVICES = window._env_
	? window._env_.URL_SERVICES
	: 'https://lk.kloud.one'; // : 'https://lkdev.etcd.kloud.one';
export const URL_SERVICE_ID = window._env_
	? window._env_.URL_SERVICE_ID
	: 'https://flashcall.kloud.one/'; // : 'https://id.etcd.kloud.one/';
