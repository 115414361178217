import { FC } from 'react';
import Header from '../Header/Header';
import { useAppSelector } from '../../hooks/redux';
import TableOrders from '../TableOrders/TableOrders';
import TableLogs from '../TableLogs/TableLogs';

const Main: FC = () => {
	const { view } = useAppSelector((state) => state.viewReducer);
	return (
		<>
			<Header />
			{view === 'log' ? <TableLogs /> : null}
			{view === 'orders' ? <TableOrders /> : null}
		</>
	);
};

export default Main;
