import styles from './AppIconButton.module.css';

export default function AppIconButton(): JSX.Element {
	return (
		<div className={styles.appIcon}>
			<svg
				width="24"
				height="24"
				viewBox="0 0 24 24"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M4 4H6.66667V6.66667H4V4ZM17.3333 4H20V6.66667H17.3333V4ZM6.66667 10.6667H4V13.3333H6.66667V10.6667ZM17.3333 10.6667H20V13.3333H17.3333V10.6667ZM6.66667 17.3333H4V20H6.66667V17.3333ZM17.3333 17.3333H20V20H17.3333V17.3333ZM13.3333 4H10.6667V6.66667H13.3333V4ZM10.6667 10.6667H13.3333V13.3333H10.6667V10.6667ZM13.3333 17.3333H10.6667V20H13.3333V17.3333Z"
					fill="#000000"
				/>
			</svg>
		</div>
	);
}
