import React, { FC, useState } from 'react';
import {
	Fade,
	IconButton,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
} from '@mui/material';
import { URL_SERVICES, URL_SERVICE_ID } from '../../utils/const';
import styles from './LinksServices.module.css';
import AuthorizationIcon from '../Icons/Authorization/AuthorizationIcon';
import AutocallIcon from '../Icons/Autocall/AutocallIcon';
import GetterIcon from '../Icons/Getter/GetterIcon';
import AppIconButton from '../Icons/AppIcon/AppIconButton';
import IconTooltip from '../IconTooltip/indexIconTooltip';

const LinksServices: FC = () => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLink = (service: string) => {
		setAnchorEl(null);
		window.open(`${URL_SERVICES}/${service}`, '_blank');
	};

	const handleLinkID = () => {
		setAnchorEl(null);
		window.open(`${URL_SERVICE_ID}`, '_blank');
	};

	return (
		<div className={styles.links_services_button_wrapper}>
			<IconTooltip
				TransitionComponent={Fade}
				TransitionProps={{ timeout: 600 }}
				title="Приложения платформы kloud.one"
				placement="bottom-start"
				arrow
			>
				<div
					className={`${styles.links_services_button} disable-mui-background`}
				>
					<IconButton
						onClick={handleClick}
						size="small"
						aria-controls={open ? 'basic-menu' : undefined}
						aria-haspopup="true"
						aria-expanded={open ? 'false' : undefined}
						className={styles.svg_app}
					>
						<AppIconButton />
					</IconButton>
				</div>
			</IconTooltip>
			<Menu
				className={styles.links_services_button__menu}
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				<MenuItem
					onClick={() => handleLink('counter')}
					className={styles.links_services_button__menu_item}
				>
					<ListItemIcon>
						<GetterIcon />
					</ListItemIcon>
					<ListItemText>Прием показаний счетчиков</ListItemText>
				</MenuItem>
				<MenuItem
					onClick={() => handleLink('obzvon')}
					className={styles.links_services_button__menu_item}
				>
					<ListItemIcon>
						<AutocallIcon />
					</ListItemIcon>
					<ListItemText>Обзвон должников</ListItemText>
				</MenuItem>
				<MenuItem
					onClick={() => handleLinkID()}
					className={styles.links_services_button__menu_item}
				>
					<ListItemIcon>
						<AuthorizationIcon />
					</ListItemIcon>
					<ListItemText>Авторизация по звонку</ListItemText>
				</MenuItem>
			</Menu>
		</div>
	);
};

export default LinksServices;
