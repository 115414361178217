import { createSlice } from '@reduxjs/toolkit';

export type PageState = {
	data: string;
	table: string;
};

const initialState: PageState = {
	data: 'Заказы',
	table: 'Настройки подключения',
};

export const pageSlice = createSlice({
	name: 'page',
	initialState,
	reducers: {
		setDataPage: (state, data) => {
			state.data = data.payload;
		},
		setDataPageTable: (state, data) => {
			state.table = data.payload;
		},
	},
});

export const { setDataPage, setDataPageTable } = pageSlice.actions;

export default pageSlice.reducer;
