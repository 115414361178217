import resolve from './resolve';
import axios from 'axios';
import { ICall } from '../models/ICall';
import { API_PREFIX } from '../utils/const';

interface returnCallsI {
	total: number;
	data: ICall[];
	success: boolean;
}
interface searchI {
	[key: string]: string;
}

export async function returnCalls(
	page: number,
	searchOption: string,
	skip: number,
	limit: number,
	sort: {
		sortField: any;
	},
	// clientID: string,
	order: {
		sortOrder: any;
	},
	searchStart: string,
	searchEnd: string,
	search: searchI,
	callType: string
): Promise<any> {
	const reqSearch = () => {
		// const normalizedGdate = (date: string): string => {
		// 	return date.replace(/-/g, '.');
		// };
		const searchObj: searchI = Object.assign({}, search, {
			date: {
				gte: new Date(searchStart).getTime(),
				lte: new Date(searchEnd).getTime(),
			},
		});

		// Object.keys(search).forEach((key) => {
		// 	if (search[key].length > 0) {
		// 		searchObj[key] = search[key];
		// 	}
		// });
		return JSON.stringify(searchObj);
	};
	return await resolve(
		// axios.get<returnCallsI>(`${URL_SERVER}/m_0001/calls/tableData/report`, {
		axios.get<returnCallsI>(`${API_PREFIX}/calls`, {
			withCredentials: true,
			params: {
				page: page,
				search: reqSearch(),
				searchOption: searchOption,
				skip: skip,
				limit: limit,
				sort: sort.sortField,
				order: order.sortOrder,
				callType: callType,
			},
		})
	);
}
