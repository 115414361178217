export default function GetterIcon(): JSX.Element {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.44287 13.8662C2.44443 19.1179 6.72298 23.375 12 23.375C17.277 23.375 21.5556 19.1179 21.5571 13.8662H16.7406C16.7391 16.4702 14.6168 18.5814 12 18.5814C9.38325 18.5814 7.26094 16.4702 7.25938 13.8662H2.44287Z"
				fill="#2196F3"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.4912 1.70632L0.300825 8.13647C0.0138352 8.30139 -0.0854887 8.67004 0.0803115 8.95591L1.70259 11.7524C1.86839 12.0383 2.23884 12.137 2.52583 11.9721L12 6.52811L21.4742 11.9721C21.7611 12.137 22.1316 12.0383 22.2974 11.7524L23.9197 8.95591C24.0855 8.67004 23.9861 8.30139 23.6992 8.13647L12.5087 1.70632C12.3201 1.59789 11.6799 1.59789 11.4912 1.70632Z"
				fill="#98CFFA"
			/>
			<path
				d="M12.9046 11.2998H11.0957C10.5717 11.2998 10.147 11.7225 10.147 12.244V14.0444C10.147 14.5659 10.5717 14.9886 11.0957 14.9886H12.9046C13.4286 14.9886 13.8533 14.5659 13.8533 14.0444V12.244C13.8533 11.7225 13.4286 11.2998 12.9046 11.2998Z"
				fill="#98CFFA"
			/>
		</svg>
	);
}
