import React, { FC, useEffect, useState } from 'react';
import { Dialog, DialogTitle, IconButton, InputAdornment } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
	testSip,
	changeSip,
	changeDisplayName,
	getProfile,
	changeApiKey,
} from '../../service/UsersService';
import { setErrorApp, setInfoApp } from '../../store/reducers/InfoSlice';

import styles from './profileForm.module.css';
import { LoadingButton } from '@mui/lab';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { fetchProfile } from '../../service/ProfileServiceThunk';
import ReactDOM from 'react-dom';

interface ProfileFormProps {
	open: boolean;
	handleClose: () => void;
}

const ProfileForm: FC<ProfileFormProps> = ({ open, handleClose }) => {
	const { profile } = useAppSelector((state) => state.profileReducer);
	const serverSipValue: string =
		typeof profile?.server_sip_shop === 'string'
			? profile?.server_sip_shop
			: 'multifon.ru';
	const loginSipValue: string =
		typeof profile?.phone_shop === 'string' ? profile?.phone_shop : '';
	const passSipValue: string =
		typeof profile?.phone_pass_shop === 'string'
			? profile?.phone_pass_shop
			: '';
	const lineSipValue: number =
		typeof profile?.lines_shop === 'number' ? profile?.lines_shop : 1;
	const apiKeyValue: string =
		typeof profile?.apiKey === 'string' ? profile?.apiKey : '';
	const displayNameValue: string =
		typeof profile?.display_name === 'string' ? profile?.display_name : '';

	const [loginSip, setLogin] = useState<string>(loginSipValue);
	const [passSip, setPass] = useState<string>(passSipValue);
	const [serverSip, setServer] = useState<string>(serverSipValue);
	const [lineSip, setLine] = useState<number>(lineSipValue);
	const [loading, setLoading] = useState<boolean>(false);
	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [displayName, setDisplayName] = useState<string>('');
	const [apiKey, setApiKey] = useState<string>(apiKeyValue);

	useEffect(() => {
		setServer(serverSipValue);
		setLine(lineSipValue);
		setLogin(loginSipValue);
		setPass(passSipValue);
		setDisplayName(displayNameValue);
		setApiKey(apiKeyValue);
	}, [profile, open]);

	const handleCloseMainDialog = () => {
		handleClose();
	};

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event: { preventDefault: () => void }) => {
		event.preventDefault();
	};
	const dispatch = useAppDispatch();

	const handleChange = (
		evt: React.ChangeEvent<HTMLInputElement>,
		type: string
	) => {
		if (type === 'login') {
			setLogin(evt.target.value);
		} else if (type === 'password') {
			setPass(evt.target.value);
		} else if (type === 'server') {
			if (/^[0-9a-z.:]+$/gi.test(evt.target.value) || evt.target.value === '') {
				setServer(evt.target.value);
			}
		} else if (type === 'lines') {
			if (
				(/^\d+$/.test(evt.target.value) && Number(evt.target.value) <= 60) ||
				evt.target.value === ''
			) {
				setLine(Number(evt.target.value));
			}
		} else if (type === 'displayName') {
			setDisplayName(evt.target.value);
		} else if (type === 'apiKey') {
			setApiKey(evt.target.value);
		}
	};

	const onTestSip = async () => {
		setLoading(true);
		const response = await testSip({
			lines_shop: String(lineSip),
			phone_shop: loginSip,
			phone_pass_shop: passSip,
			server_sip_shop: serverSip,
		});
		// console.log(response,'response');
		if (response.error) {
			setLoading(false);
			dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Произошла ошибка при проверке статуса сип',
				})
			);
		} else {
			setLoading(false);
			const { statusSip, timeUpdateSip } = response.data.data;
			let message: string;
			if (statusSip === null || timeUpdateSip === null) {
				message = 'Произошли проблемы обратитесь в техподдержку';
			} else {
				message = `Статус сип-соединения: "${statusSip}", время проверки: "${timeUpdateSip}"`;
			}
			dispatch(setInfoApp({ isInfoApp: true, infoApp: message }));
		}
	};

	const onChangeSip = async () => {
		const profilePromise = await getProfile();
		if (profilePromise) {
			const user = profilePromise.data.data;
			if (
				// user?.payment_type.includes('answers') &&
				user?.phone_getter === loginSip &&
				user?.server_sip_getter === serverSip
			) {
				return dispatch(
					setErrorApp({
						isErrorApp: true,
						errorApp:
							'Данное сип-соединение уже используется в приеме показаний',
					})
				);
			} else if (
				// user?.payment_type.includes('calls') &&
				user?.phone_autocall === loginSip &&
				user?.server_sip_autocall === serverSip
			) {
				return dispatch(
					setErrorApp({
						isErrorApp: true,
						errorApp:
							'Данное сип-соединение уже используется в обзвоне должников',
					})
				);
			}
		}
		if (
			loginSip.length === 0 ||
			!loginSip.trim() ||
			passSip.length === 0 ||
			!passSip.trim() ||
			serverSip.length === 0 ||
			lineSip === 0
		) {
			dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Необходимо заполнить обязательные поля',
				})
			);
			return;
		}
		const response = await changeSip({
			lines_shop: String(lineSip),
			phone_shop: loginSip,
			phone_pass_shop: passSip,
			server_sip_shop: serverSip,
		});
		if (response.error) {
			dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'При обновлении профиля возникла ошибка',
				})
			);
		} else {
			dispatch(
				setInfoApp({ isInfoApp: true, infoApp: 'SIP профиль обновлен' })
			);
			dispatch(fetchProfile());
			handleCloseMainDialog();
		}
	};

	const onSaveName = async () => {
		const response = await changeDisplayName(displayName);

		if (displayName.length === 0 || !displayName.trim()) {
			dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Необходимо заполнить обязательные поля',
				})
			);
			return;
		}

		if (response.error) {
			dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: `Произошла ошибка при смене имени пользователя`,
				})
			);
		} else {
			dispatch(
				setInfoApp({
					isInfoApp: true,
					infoApp: `Имя пользователя изменено`,
				})
			);
			dispatch(fetchProfile());
			handleCloseMainDialog();
		}
	};

	const onSaveApiKey = async () => {
		const response = await changeApiKey(apiKey);

		if (apiKey.length === 0 || !apiKey.trim()) {
			dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: 'Необходимо заполнить обязательные поля',
				})
			);
			return;
		}

		if (response.error) {
			dispatch(
				setErrorApp({
					isErrorApp: true,
					errorApp: `Произошла ошибка при смене apiKey`,
				})
			);
		} else {
			dispatch(
				setInfoApp({
					isInfoApp: true,
					infoApp: `apiKey пользователя изменен`,
				})
			);
			dispatch(fetchProfile());
			handleCloseMainDialog();
		}
	};

	return ReactDOM.createPortal(
		<Dialog open={open} onClose={handleCloseMainDialog}>
			<DialogTitle className={styles.profile_label}>
				Настройки подключения
				<button onClick={handleCloseMainDialog} className={styles.close_modal}>
					×
				</button>
			</DialogTitle>
			<div className={styles.container}>
				<div className={styles.wrapper_for_sip_options}>
					<DialogContent className={styles.dialog_content}>
						<DialogContentText className={styles.label_for_wrapper_sip}>
							SIP аккаунт
						</DialogContentText>
						<TextField
							margin="dense"
							id="login_sip"
							label="Логин sip"
							fullWidth
							variant="standard"
							defaultValue={loginSipValue}
							onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
								handleChange(evt, 'login')
							}
							error={loginSip.length === 0 || !loginSip.trim()}
						/>
						<TextField
							margin="dense"
							// id="outlined-adornment-password"
							fullWidth
							variant="standard"
							className={styles.password_input}
							type={showPassword ? 'text' : 'password'}
							defaultValue={passSipValue}
							onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
								handleChange(evt, 'password')
							}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											edge="end"
										>
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								),
							}}
							label="Пароль sip"
							error={passSip.length === 0 || !passSip.trim()}
						/>
						<TextField
							margin="dense"
							id="server_sip"
							label="Адрес sip сервера"
							fullWidth
							variant="standard"
							value={serverSip}
							onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
								handleChange(evt, 'server')
							}
							error={serverSip.length <= 0}
						/>
						<TextField
							inputProps={{ inputMode: 'numeric', min: 1, max: 60 }}
							margin="dense"
							id="line_sip"
							label="Количество линий"
							fullWidth
							variant="standard"
							value={lineSip}
							onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
								handleChange(evt, 'lines')
							}
							required
						/>
					</DialogContent>
					<DialogActions className={styles.wrapper_for_buttons}>
						<Button
							disabled={
								loginSip === loginSipValue &&
								passSip === passSipValue &&
								serverSip === serverSipValue &&
								lineSip === lineSipValue
							}
							onClick={onChangeSip}
						>
							Сохранить
						</Button>
						{/* <LoadingButton
							disabled={
								loginSip.length === 0 ||
								(!loginSip.trim()) ||
								passSip.length === 0 ||
								(!passSip.trim()) ||
								serverSip.length === 0 ||
								(!serverSip.trim()) ||
								Number(lineSip) > 60 ||
								Number(lineSip) < 1
							}
							loading={loading}
							onClick={onTestSip}
						>
							Проверка sip-соединения
						</LoadingButton> */}
					</DialogActions>
				</div>
				<div className={styles.wrapper_for_sip_options}>
					<DialogContent className={styles.dialog_content}>
						<DialogContentText className={styles.label_for_wrapper_sip}>
							Изменение имени пользователя
						</DialogContentText>
						<TextField
							margin="dense"
							id="display_name"
							label="Имя пользователя"
							fullWidth
							variant="standard"
							defaultValue={displayNameValue}
							onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
								handleChange(evt, 'displayName')
							}
						/>
					</DialogContent>
					<DialogActions className={styles.wrapper_for_buttons_key}>
						<Button
							disabled={
								displayName.length === 0 ||
								displayName.length >= 50 ||
								!displayName.trim() ||
								displayName === displayNameValue
							}
							onClick={onSaveName}
						>
							Сохранить
						</Button>
					</DialogActions>
				</div>
				<div className={styles.wrapper_for_sip_options}>
					<DialogContent className={styles.dialog_content}>
						<DialogContentText className={styles.label_for_wrapper_sip}>
							Изменение api ключа
						</DialogContentText>
						<TextField
							margin="dense"
							id="apiKey"
							label="api ключ"
							fullWidth
							variant="standard"
							defaultValue={apiKeyValue}
							onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
								handleChange(evt, 'apiKey')
							}
						/>
					</DialogContent>
					<DialogActions className={styles.wrapper_for_buttons_key}>
						<Button
							disabled={
								apiKey.length === 0 ||
								apiKey.length >= 50 ||
								!apiKey.trim() ||
								apiKey === apiKeyValue
							}
							onClick={onSaveApiKey}
						>
							Сохранить
						</Button>
					</DialogActions>
				</div>
			</div>
		</Dialog>,
		document.getElementById('profileForm')!
	);
};

export default ProfileForm;
